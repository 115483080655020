<template>
  <b-overlay :show="isFetchingCmsSite">
    <b-row>
      <b-col class="text-right mb-2">
        <b-button
          variant="success"
          @click="$bvModal.show('cms-create-game-cover')"
          >New Game Cover</b-button
        >
      </b-col>
    </b-row>
    <b-table :fields="fields" :items="cmsGameCovers" responsive>
      <template #cell(#)="data">
        <div>
          {{ data.index + 1 }}
        </div>
      </template>
      <template #cell(imageSrc)="data">
        <b-img :src="data.value" width="auto" height="150px"></b-img>
      </template>
      <template #cell(gameId)="data">
        <div>
          <b-badge variant="info">{{ getGameName(data.value) }}</b-badge>
        </div>
      </template>
    </b-table>
    <CreateCmsGameCover />
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    CreateCmsGameCover: () => import('./create-game-cover.vue'),
  },
  data() {
    return {
      fields: [
        '#',
        {
          key: 'imageSrc',
          label: 'Image',
        },
        {
          key: 'gameId',
          label: 'Game provider name',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['cmsGameCovers', 'siteId', 'providerGames']),
    ...mapState({
      isFetchingCmsSite: (state) => state.cms.isFetchingCmsSite,
    }),
  },
  mounted() {
    this.fetchProviderGames()
  },
  methods: {
    ...mapActions(['fetchProviderGames']),
    getGameName(gameId) {
      return this.providerGames.find((pg) => pg.id === gameId)?.name || '-'
    },
  },
}
</script>
